<template>
  <div class="w-full h-full bg-gray-100">
    <BoardHeader> 
      <template #right-side>
        <UserProfileVue :profile="userProfile" @logout="handleLogout" />
      </template>
    </BoardHeader>

    <div class="w-full h-full py-10">
      <div class="transform-all grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 container gap-2 mx-auto px-4 md:px-0" >
        <Card :image="card.icon" :title="card.title" :link="card.url" v-for="card in filteredItems" :key="card.id" :isCustom="card.has_sso" @click="redirectWithSSOToken" />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { client, revokeToken } from '@/utils/api.js';
  import { ref, onMounted, computed } from 'vue';
  import Card from './CardPage.vue'
  import BoardHeader from './BoardHeader.vue'
  import { useRouter } from 'vue-router';
  import UserProfileVue from './UserProfile.vue';

  const items = ref([]);
  const isLoading = ref(true);
  const id = ref(localStorage.getItem('localUserID'))
  const userProfile = ref({
    name: '',
    picture: '',
    bu: '',
    hasProfile: false
  })
  const router = useRouter()

  const filteredItems = computed(() => items.value.filter(item => item.enabled))

  onMounted(async () => {
    client.listers.push(setUserId)
    try {
        
        setApps()
        loadUserProfile();
        isLoading.value = false;
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      router.replace('/login')
    }
  });

  function redirectWithSSOToken(link) {
    client.retrieveValidToken()
    .then((accessToken) => {
      if (accessToken) document.location = `${link}?shared_token=${accessToken}`
      else console.error('[ ERROR ] Mising token to redirect.')
    })
    .catch((error) => {
      console.error(error)
      document.location = link
    })
  }

  function handleLogout() {
    const refresh = localStorage.getItem('refreshToken')
    revokeToken(refresh)
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('localApps')
    localStorage.removeItem('userData')
    router.replace('/login')
  }

  function setUserId(newid) {
    id.value = newid
  }

  function loadUserProfile() {
    let userData = localStorage.getItem('userData')

    if (!userData) return;

    userData = JSON.parse(userData)
    userProfile.value = {name: userData.name, picture: userData.picture, bu: userData.hd, hasProfile: !!userData.name}
  }

  async function setApps(){
    try {
      const data = await client.api.getAppsUser()
      console.log('apps', data)
      localStorage.setItem('localApps', JSON.stringify(data))
      items.value = (data.results);
    } catch (error) {
      console.log('catch', error)
      items.value = []
    }
    
  }

</script>

<style>
  #app {
    @apply bg-gray-100
  }
</style>
